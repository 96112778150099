import React from 'react';
import './DisclaimerFooter.scss';

export default function DisclaimerFooter({ dark }) {

    return (
        <div className={`disclaimer--footer ${dark ? 'dark' : 'light'}`}>
            <div className="links-wrapper">
                <a href="/privacy">Privacy policy</a>
                -
                <a href="/cookie">Cookie policy</a>
            </div>
        </div>
    )
}
import React, { useEffect, useState, useMemo, useRef } from 'react';
import gsap from "gsap";
import "@lottiefiles/lottie-player";
import robotAnim from '../../images/robot_anim.json';
import './LoadingScreen.scss';

export default function LoadingScreen({ style }) {
    const [robotAnime, setRobotAnime] = useState()
    const roboRef = useRef()
    const roboContRef = useRef()

    const waitText = useMemo(() => ({
        flower: [
            "We renderen de bijtjes",
            "We planten nog enkele e-bloemetjes in dat hoekje",
            "We uploaden nog snel wat geraniums",
            "Momentje, we doen nog iets met quantumcomputing",
            "Krokusjes worden geïmplementeerd",
            "Momentje, je cybergras wordt afgereden"
        ],
        eco: [
            "De dankbaarheid van de bijtjes wordt geüpdatet",
            "We laten het e-gras nog wat groeien",
            "Secondje, we laten de cybernatuur zijn gang gaan",
            "Biodiversiteit wordt gekalibreerd",
            "De e-khoorntjes worden geïmplementeerd",
            "Momentje, we doen nog iets met quantumcomputing",
            "Waterinfiltratie optimaliseren"
        ],
        medi: [
            "Het Provencegevoel wordt gerenderd",
            "\"La Bella Vita\" wordt geüpload",
            "De lavendel krijgt nog een update",
            "We kalibreren de terracotta",
            "Op zoek naar dat beetje Je-ne-sais-pas-quoi…",
            "Momentje, we doen nog iets met quantumcomputing"
        ],
        modern: [
            "De abstracte kunstwerken worden gerenderd",
            "We updaten de esthetische geometrie",
            "We maken het helemaal zen",
            "De nieuwsgierigheid van de buren wordt geüpdatet",
            "We renderen er nog wat struikjes bij",
            "Momentje, we doen nog iets met quantumcomputing"
        ],
        prairie: [
            "Het prairiegras wordt uitgelijnd",
            "We uploaden er nog rap een boompje bij",
            "We renderen de bijtjes",
            "\"Howdy partner\" wordt geladen",
            "Momentje, we doen nog iets met quantumcomputing"
        ],
        food: [
            "De wortels worden geüpload",
            "De tomaten worden gerenderd in #EC2D01",
            "We kalibreren de cyberpatatten",
            "We uploaden er nog rap wat verse komkommers bij",
            "De Oost-Indische kers wordt geüpdatet naar v4.2",
            "De rozemarijn wordt gedownload",
            "Momentje, we doen nog iets met quantumcomputing"
        ],
        adventure: [
            "We kalibreren de klautertouwen",
            "Het klimrek wordt geüpdatet naar v2.3",
            "De stapstenen worden gedownload",
            "We renderen nog wat spanning erbij",
            "De honger voor avontuur wordt geüpload",
            "Verveling wordt gedeletet",
            "Momentje, we doen nog iets met quantumcomputing"
        ],
        water: [
            "De waterlelies worden gerenderd",
            "De eendjes worden geüpdatet naar v3.4",
            "Het bruggetje wordt geïmplementeerd",
            "We renderen nog wat karpers",
            "Cyberzwembad wordt uitgegraven",
            "Momentje, we doen nog iets met quantumcomputing"
        ],

    }), []);

    const [currentText, setCurrentText] = useState(waitText[style.choice][0]);

    useEffect(() => {
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * waitText[style.choice].length);
            setCurrentText(waitText[style.choice][randomIndex]);
        }, 4000);

        return () => clearInterval(interval);
    }, [waitText, style.choice]);

    useEffect(() => {
        const robot = roboRef.current;
        const roboCont = roboContRef.current;
        const roboContDimension = roboCont.getBoundingClientRect()

        setRobotAnime(JSON.stringify(robotAnim))

        let tl = gsap.timeline();
        tl.to(robot, {
            left: () => {
                let percentage = roboContDimension.width - 180
                return `${percentage}px`
            },
            yoyo: true,
            duration: () => {
                return Math.max(roboContDimension.width / 10, 4) / 25;
            },
            repeat: -1,
            ease: "none",
            onRepeat: () => {
                robot.classList.toggle('runLeft')
            }
        })
    }, [])

    return (
        <div className="loading-screen flex flex-col items-center w-full h-full text-center flex-grow space-y-8 p-5">
            <div className='content'>
                <div ref={roboContRef} className='robot-container'>
                    <div ref={roboRef} className='robot'>
                        {robotAnime &&
                            <lottie-player
                                autoplay
                                loop
                                mode="normal"
                                src={robotAnime}
                                style={{ width: "180px" }}
                            >
                            </lottie-player>
                        }
                    </div>
                </div>
                <h1 className='waittext'>{currentText}</h1>
                {/* <p className='extra-text'>Met behulp van beeldgenererende Artificial Intelligence tonen we je wat het resultaat zou kunnen zijn. Vervolgens toont onze state-of-the-art A.I. wat jouw tuin allemaal kan worden. </p> */}
            </div>
        </div>
    )
}